
import React, { Component } from "react";
//import { establishCurrentUser } from "./reducers/auth";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SvgIcons from "./utils/SvgIcons/SvgIcons";
import NavBar from "./components/organisms/NavBar/NavBar";
import Routes from "./routes/routes";
// import ComingSoon from "../app/components/atoms/ComingSoon/ComingSoon";
import "./App.scss"; 

const isDevelopement = process.env.NODE_ENV === 'development';

// Register gsap plugins
gsap.registerPlugin(ScrollTrigger);

if (window.innerWidth < 550) {
  ScrollTrigger.config({
    // default is "resize,visibilitychange,DOMContentLoaded,load" so we can remove "resize" from the list:
    autoRefreshEvents: "visibilitychange,DOMContentLoaded,load"
  });
}


class   App extends Component {

  constructor() {
    super();
    this.state = {
      showFooter: false
    }
  }

  async componentDidMount() {

    if (!isDevelopement) {
      try {
        // this.setState({ hubStatus });
      } catch (error) {
        console.error(`Error:`, error);
      }
    }
  }



  render() {
    const { authenticated, location, user, showFooter } = this.props;

    return (
      <div id="app">
          <SvgIcons carat cross social doublearrow loading />
          <NavBar
            isBeta={true}
          />
          <Routes />

      </div>
    );
  }
}


export default App;
