
import { getCurrentPageUrl } from './helper';


export function getCurrentPageDomain() {

    //const url = getCurrentPageUrl();
    return 'activelama.com';
}



export function getEmailShareLink(link, title, description) {
    const body = encodeURIComponent(description) + '%20' + encodeURIComponent(link);
    return 'mailto:?subject=' + encodeURIComponent(title) + '&body=' + body;
}


export function getFacebookAppId(domain) {

    return '32234132412343'; //dummy
}



export function getRedditShareLink(link) {
    return 'https://www.reddit.com/submit?url=' + encodeURIComponent(link);
}



export function getSiteName(domain) {
    const testingDefault = 'The Brisbane Times';
    const siteNames = {
        theage: 'The Age',
        smh: 'The Sydney Morning Herald',
        brisbanetimes: testingDefault,
        watoday: 'WA Today',
        canberratimes: 'The Canberra Times'
    };

    for (let name in siteNames) {
        if (domain && domain.indexOf(name) >= 0) {
            return siteNames[name];
        }
    }

    return testingDefault;
}




export function getTwitterHandle(domain) {
    const testingDefault = 'brisbanetimes';
    const twitterHandles = {
        'smh.com.au': 'smh',
        'theage.com.au': 'theage',
        'watoday.com.au': 'watoday',
        'brisbanetimes.com.au': testingDefault,
        'canberratimes.com.au': 'canberratimes',
        'goodfood.com.au': 'goodfood',
        'dailylife.com.au': 'dailylife',
        'domain.com.au': 'domain'
    };

    for (let d in twitterHandles) {
        if (domain && domain.indexOf(d) >= 0) {
            return twitterHandles[d];
        }
    }

    return testingDefault;
}


export function getTwitterShareLink(link, description, twitterHandle) {
    return `https://twitter.com/share?url=${encodeURIComponent(link)}${description ? `&text=${description}` : ''
        }&via=${twitterHandle}`;
}



export function getWhatsAppShareLink(link, title, description) {
    const shareMessage = encodeURIComponent(title + ' | ' + description + ' - ' + link);
    const shareMessageHref = 'https://wa.me/?text=' + shareMessage;
    return shareMessageHref;
}



export function handleEmailShare(event) {
    const closestEmailShare = event.target.closest('.email-share-button');
    const el = closestEmailShare ? closestEmailShare : event.target;

    const link = el.getAttribute('data-share-link');
    const title = el.getAttribute('data-share-title');
    const description = el.getAttribute('data-share-description');

    if (!link || !title || !description) {
        console.error(
            'Email share buttons require link, title and description data-* attributes. One of these is missing on the event target:',
            el,
            'As such, no share attempt was made.'
        );
    }

    const shareLink = getEmailShareLink(link, title, description);
    window.open(shareLink, '_top');
}




export function handleFacebookShare(event) {
    if (window.FB) {
        const options = 'toolbar=0,status=0,resizable=1,width=626,height=436';
        const closestFacebookShare = event.target.closest('.facebook-share-button');
        const el = closestFacebookShare ? closestFacebookShare : event.target;

        const link = el.getAttribute('data-share-link');
        const description = el.getAttribute('data-share-description');
        const hashtag = el.getAttribute('data-share-hashtag');

        if (!link) {
            console.error(
                'Facebook share buttons require a `data-share-link` attribute. None was found on the event target:',
                el,
                'As such, no share attempt was made.'
            );
            return;
        }

        const encodedLink = encodeURIComponent(link);
        let shareable = `https://www.facebook.com/sharer/sharer.php?display=popup&u=${encodedLink}`;

        if (description) {
            shareable += `&quote=${encodeURIComponent(description)}`;
        }
        if (hashtag) {
            shareable += `&hashtag=${encodeURIComponent(`#${hashtag}`)}`;
        }

        window.open(shareable, 'sharer', options);
    } else {
        console.error(
            'Facebook share attempt failed because Facebook sharing library has not been setup.'
        );
    }
}



export function handleClickRedditShareButton(event) {
    const closestRedditShare = event.target.closest('.reddit-share-button');
    const el = closestRedditShare ? closestRedditShare : event.target;

    const link = el.getAttribute('data-share-link');

    if (!link) {
        console.error(
            'Reddit share buttons require a `data-share-link` attribute. None was found on the event target:',
            el,
            'As such, no share attempt was made.'
        );
        return;
    }

    const shareLink = getRedditShareLink(link);
    window.open(shareLink, 'redditShareDialog', 'width=626,height=436,scrollbars=1');
}



export function handleTwitterShare(event) {
    const domain = getCurrentPageDomain();
    const twitterHandle = getTwitterHandle(domain);

    const closestTwitterShare = event.target.closest('.twitter-share-button');
    const el = closestTwitterShare ? closestTwitterShare : event.target;

    const link = el.getAttribute('data-share-link');
    const description = el.getAttribute('data-share-description');

    if (!link) {
        console.error(
            'Twitter share buttons require a `data-share-link` attribute. None was found on the event target:',
            el,
            'As such, no share attempt was made.'
        );
        return;
    }

    const shareLink = getTwitterShareLink(link, description, twitterHandle);
    window.open(shareLink, 'twitterShareDialog', 'width=626,height=436,scrollbars=1');
}




export function handleWhatsAppShare(event) {
    const closestWhatsAppShare = event.target.closest('.whatsapp-share-button');
    const el = closestWhatsAppShare ? closestWhatsAppShare : event.target;

    const link = el.getAttribute('data-share-link');
    const title = el.getAttribute('data-share-title');
    const description = el.getAttribute('data-share-description');

    if (!link || !title || !description) {
        console.error(
            'WhatsApp share buttons require link, title and description data-* attributes. One of these is missing on the event target:',
            el,
            'As such, no share attempt was made.'
        );
        return;
    }

    const shareLink = getWhatsAppShareLink(link, title, description);
    window.open(shareLink, 'whatsAppShareDialog', 'width=626,height=436,scrollbars=1');
}




export function setupFacebookSharingLibrary() {
    window.fbAsyncInit = function () {
        const domain = getCurrentPageDomain();

        window.FB.init({
            appId: getFacebookAppId(domain),
            xfbml: true,
            version: 'v2.3'
        });
    };

    (function (d, s, id) {
        var js,
            fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
            return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = '//connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
}



/*
  Sharing metadata - Set up those parts of the site's sharing metadata that aren't affected
  by the user's results.

  This function is a little shitty and is likely to be safely superceded by things like React/Svelte
  Helmet you'll find in libraries you're already using, but it's included for continuity's sake
  - and on the off-chance that you need it.
*/

/*
export type SharingMetadata = {
  title: string,
  description: string,
  url?: string,
  keyWords?: Array<string>,
  author?: Array<string>
};
*/



export function setupSharingMetadata({ title, description, url, keyWords, author }) {
    if (!title || !description) {
        console.error(
            '[ERR] Setup sharing metadata: Required values missing. Sharing metadata ' +
            'requires title, description and url properties, at minimum.'
        );
    }

    const domain = getCurrentPageDomain();

    // *-> Facebook
    document.head.querySelector("[property='fb:app_id']").content = getFacebookAppId(domain);
    document.head.querySelector("[property='og:title']").content = title;
    document.head.querySelector("[property='og:url']").content = url || getCurrentPageUrl();
    document.head.querySelector("[property='og:description']").content = description;
    document.head.querySelector("[property='og:site_name']").content = getSiteName(domain);

    // *-> Twitter
    document.head.querySelector("[name='twitter:site']").content = getTwitterHandle(domain);
    document.head.querySelector("[name='twitter:title']").content = title;
    document.head.querySelector("[name='twitter:description']").content = description;
    document.head.querySelector("[name='twitter:creator']").content = getTwitterHandle(domain);
    document.head.querySelector("[name='twitter:url']").content = url || getCurrentPageUrl();

    // *-> Schema
    document.head.querySelector("[name='description']").content = description;
    document.head.querySelector("[name='news_keywords']").content = keyWords;
    document.head.querySelector("[itemprop='name']").content = description;
    document.head.querySelector("[itemprop='description']").content = description;
    document.head.querySelector("[itemprop='author']").content = author ? author.join(', ') : '';

    // *-> Other
    document.head.querySelector("[rel='canonical']").href = url || getCurrentPageUrl();
    document.head.querySelector("[rel='standout']").href = url || getCurrentPageUrl();
}



export default {
    setupSharingMetadata,
    getTwitterHandle,
    setupFacebookSharingLibrary,
    handleWhatsAppShare,
    handleTwitterShare,
    handleClickRedditShareButton,
    handleFacebookShare,
    handleEmailShare,
    getTwitterShareLink,
    getWhatsAppShareLink,
    getSiteName,
    getRedditShareLink,
    getEmailShareLink,
    getFacebookAppId,
    getCurrentPageDomain
}
