
import React, { Component } from "react";
import SocialMediaBar from '../../molecules/SocialMediaBar';
import './Footer.scss';

// type Props = {
//   title: string,
//   masthead: string,
//   screenSize: string,
//   shareUrl: string,
//   description: string,
//   initFacebookLibrary: boolean
// };

const opt = { title: "Some title", masthead: 'smh', screenSize: 'medium', shareUrl: 'https://activelama.com', description: 'Some description for lamainteractives', initFacebookLibrary: true };



class Footer extends Component {
  constructor(props: Props) {
    super(props);
    this.state = {
      showFooter:false
    };
  }

  componentDidMount(){
    //setTimeout(()=>{
      this.setState({showFooter:true});
    //}, 1200)
  }

  render() {
    const{showFooter} = this.state;

    if(!showFooter) return null;

    return (
      <div className="footer-container">
        <div className="footer">
          <div className="footer__header">
            <div className="footer__header__logo-container">
              <div className={`footer__header__logo-container__logo is--${opt.masthead}`}>
                <div
                  className="footer__header__logo-container__logo__image"
                  style={{
                    backgroundImage: `url(./images/logo_black_text_tans.png)`
                  }}
                />
                {/* eslint-disable-next-line */}
                <a
                  className="footer__header__logo-container__logo__link"
                  href={`https://www.${opt.masthead}.com.au`}
                />
              </div>
            </div>

            {/* <SocialMediaBar
          screenSize={opt.screenSize}
          shareUrl={opt.shareUrl}
          link={opt.shareUrl}
          title={opt.title}
          description={opt.description}
          initFacebookLibrary={opt.initFacebookLibrary}
          customContainerClassName="socialButtonContainer footer__header__social"
          customButtonClassName="socialButton footer__header__social__button"
        /> */}
          </div>

          <div className="footer__copyright">
            <p className="footer__copyright__text">© {new Date().getFullYear()} ACTIVELAMA.COM</p>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer;
