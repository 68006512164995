// @flow

import React from "react";
import "./DynamicImportLoader.scss";
import image from "./loader.svg";

type Props = {
  error: boolean,
  pastDelay: boolean,
  retry: Function,
  timedOut: boolean
};
// See 'Avoiding Flash Of Loading Component' for reason as to this 'pastDelay' prop
// and its use here: https://github.com/jamiebuilds/react-loadable
const DynamicImportLoader = ({ pastDelay }: Props): null | React$Element<"div"> =>
  pastDelay ? (
    <div className="dynamic-import-loader">
      <img
        className="dynamic-import-loader__image"
        src={image}
        alt="Loading..."
      />
    </div>
  ) : null;

export default DynamicImportLoader;
