
import React, { Component } from "react";
//import { Link } from "react-router-dom";
import {APP_SITE } from "../../../../config";
// import { logoutUser } from "../../../reducers/auth";
//import NavBarLogo from "../../atoms/NavBarLogo/NavBarLogo";
import "./NavBar.scss";


let lastScroll = 0;

export default class NavBar extends Component {
  constructor() {
    super();
    this.state = {
      searchActive: false,
      lineInputCheck: false,
      isRichard: false
    }
  }

  componentDidMount() {

    const isRichard = window.location.href.includes('richard-lama');
    document.addEventListener('scroll', this.handleScroll);
    document.addEventListener('mousedown', this.handleClickOutside);

    this.setState({isRichard})
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll);
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleScroll = (e) => {
    const headerDiv = document.getElementById("n-header");
    if (window.scrollY > 150) {
      headerDiv.style.top = '-45px';
    } else {
      // headerDiv.style.top = '0';
    }
    if (window.scrollY < lastScroll) {
      headerDiv.style.top = '0';
    }
    lastScroll = window.scrollY;
  }

  handleClickOutside = (e) => {
    // console.log(e.target.getAttribute('type'))
    // if(e.target.getAttribute('type') === 'text'){
    //   console.log("ddd")
    //   this.setState({ searchActive: true })
    // }else{
    //   this.setState({ searchActive: false })
    // }
  };

  // logoutUser = () => {
  //   this.props.logoutUser();
  //   this.props.history.push("/");
  // };

  // onSearch = () => {
  //   console.log("click")
  // }

  // activateSearchBtn = (t) => {
  //   const { searchActive } = this.state;

  //   // if (t && !searchActive) {
  //   //   this.setState({ searchActive: true })
  //   // }
  //   // if(!t && searchActive){
  //   //   this.setState({searchActive:false})
  //   // }
  //   // console.log(t)
  // }

  onMobileMenuClick = (event) => {
    //const { lineInputCheck } = this.state;
    // event.preventDefault()
    //console.log(event)
    // const menuDiv = document.getElementById("menu");
    // menuDiv.style.transform = 'translate(-105%, 5px)'; 
    // menuDiv.style.transformOrigin = '0% 0%'; 

    const lineInput = document.getElementById("line-input");

    //lineInput 

    const checkedVal = lineInput.checked;//('checked') ;


    const menuDiv = document.getElementById("menu");
    menuDiv.style.transform = checkedVal ? 'translate(-4%, 5px)' : 'translate(-105%, 5px)';
    menuDiv.style.transformOrigin = '0% 0%';

    const firstSpanLine = document.getElementById("first-line");
    const secondSpanLine = document.getElementById("second-line");
    const thirdSpanLine = document.getElementById("third-line");

    firstSpanLine.style.background = checkedVal ? '#000000' : ' #f2f2f2';
    secondSpanLine.style.background = checkedVal ? '#000000' : '#f2f2f2';
    thirdSpanLine.style.background = checkedVal ? '#000000' : '#f2f2f2';


    firstSpanLine.style.transformOrigin = '2px 0px';
    firstSpanLine.style.transform = checkedVal ? 'rotate(45deg) translate(-2px, -1px)' : 'translate(0%, 0%)';


    secondSpanLine.style.opacity = checkedVal ? '0' : '1';
    secondSpanLine.style.transform = 'translate(0%, 0%)';

    thirdSpanLine.style.transformOrigin = '1px 2px';
    thirdSpanLine.style.transform = checkedVal ? 'rotate(-45deg) translate(-2px, -1px)' : 'translate(0%, 0%)';

  }


  onMobileMenuAnchorClick = (event, t) => {
    //const { lineInputCheck } = this.state;
    event.preventDefault();

    document.getElementById(t).scrollIntoView({ behavior: 'smooth', block: 'start' });

    const menuDiv = document.getElementById("menu");
    menuDiv.style.transform = 'translate(-105%, 5px)';
    menuDiv.style.transformOrigin = '0% 0%';

    const firstSpanLine = document.getElementById("first-line");
    const secondSpanLine = document.getElementById("second-line");
    const thirdSpanLine = document.getElementById("third-line");
    const lineInput = document.getElementById("line-input");

    lineInput.checked = false;
    // lineInput.setAttribute('checked',true) ;
    // lineInput.style.webkitTouchCallout ='initial';

    firstSpanLine.style.background = '#f2f2f2';
    secondSpanLine.style.background = '#f2f2f2';
    thirdSpanLine.style.background = '#f2f2f2';

    secondSpanLine.style.opacity = '1';
    secondSpanLine.style.transform = 'translate(0%, 0%)';

    firstSpanLine.style.transform = 'rotate(0) translate(0%, 0%)';
    thirdSpanLine.style.transform = 'rotate(0)';

  }



  render() {
    //const { authenticated, isBeta } = this.props;
    const { searchActive, isRichard } = this.state;

    return (
      <header id="n-header" className="nav-bar">

        <div className="nav-bar__content">

          {/* <Link to="/" alt="Welcome | Nine Publishing Prez Hub">
          <div className="nav-bar__logo">
            <p>LAMAINTERACTIVES</p>
          </div>
        </Link> */}
          {/* <button className="nav-bar__logout" onClick={this.logoutUser}>
          <span>Logout</span>
        </button> */}


          <p><img src={`${APP_SITE}/images/logo_white_text_tans.png`} alt="logo" /></p>

          {isRichard &&
          <>
            <div className="nav-menu-desktop">
              <a href="#" onClick={(event) => { event.preventDefault(); document.getElementById("top-projects").scrollIntoView({ behavior: 'smooth', block: 'start' }); }}>Top Projects </a>

              <a href="#" onClick={(event) => { event.preventDefault(); document.getElementById("projects-collection").scrollIntoView({ behavior: 'smooth', block: 'start' }); }}>
                Projects Archive
              </a>

              <a href="#" onClick={(event) => { event.preventDefault(); document.getElementById("contact-form").scrollIntoView({ behavior: 'smooth', block: 'start' }); }}>
                Contact
              </a>
            </div>

            <div className="nav-menu-mobile">
              <nav role="navigation">
                <div id="menuToggle">

                  <input type="checkbox" id="line-input" onChange={this.onMobileMenuClick} />

                  <span id="first-line"></span>
                  <span id="second-line"></span>
                  <span id="third-line"></span>

                  <ul id="menu" >
                    <a href="#" onClick={(e) => this.onMobileMenuAnchorClick(e, "top-projects")}>
                      <li>Top Projects</li>
                    </a>

                    <a href="#" onClick={(e) => this.onMobileMenuAnchorClick(e, "projects-collection")}>
                      <li>Projects Archive</li>
                    </a>

                    <a href="#" onClick={(e) => this.onMobileMenuAnchorClick(e, "contact-form")}>
                      <li>Contact</li>
                    </a>
                  </ul>
                </div>
              </nav>
            </div>
          </>
          }

          {/* {authenticated && (
          <button className="nav-bar__logout" onClick={this.logoutUser}>
          <span>Logout</span>
          </button>
        )} */}


          {/* Search field */}
          {/* <form className="search-form">
            <div className="input-group search-group">
              <input type="text" className="form-control search-control" placeholder="Enter your search term..." />
              <span>
                <img src="./images/search-icon-orange.png" onClick={this.onSearch} style={{ cursor: searchActive ? 'pointer' : 'none', pointerEvents: searchActive ? 'auto' : 'none' }} />
              </span>
            </div>
          </form> */}

        </div>

      </header>
    );
  }
}

// const mapDispatchToProps = dispatch =>
//   bindActionCreators({ logoutUser }, dispatch);


// // TODO: Type this properly. See the following thread: https://github.com/facebook/flow/issues/7125
// export default connect < any, any, any, _, _, _ > (
//   null,
//   mapDispatchToProps
// )(NavBar);
