
export function getCurrentDateTime(d) {
  var now = new Date();
  if(d){
    now = new Date(d);
  }
  var now = new Date();
  var year = now.getFullYear();
  var month = now.getMonth() + 1;
  var day = now.getDate();
  var hour = now.getHours();
  var minute = now.getMinutes();
  var second = now.getSeconds();
  if (month.toString().length === 1) {
    var month = '0' + month;
  }
  if (day.toString().length === 1) {
    var day = '0' + day;
  }
  if (hour.toString().length === 1) {
    var hour = '0' + hour;
  }
  if (minute.toString().length === 1) {
    var minute = '0' + minute;
  }
  if (second.toString().length === 1) {
    var second = '0' + second;
  }
  var dateTime = year + '/' + month + '/' + day + ' ' + hour + ':' + minute + ':' + second;
  return dateTime;
}



//* add the preceding Zero to a single digit Number   
export function pad(n){return n<10 ? '0'+n : n}


const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
"Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

const daysFull = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const daysSort = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];



export function updatedDate(_date) {

  let d = new Date();
  if(_date){
    d= new Date(_date)
  }
  //d.setDate(d.getDate() + 1)

  var day = d.getDate();
  var monthIndex = d.getMonth();
  var year = d.getFullYear();
  var dayIndex = d.getDay();

  var monNumber = String(monthIndex + 1);
  monNumber = monNumber.length === 1 ? '0' + monNumber : monNumber;

  return day + ' ' + monthNames[monthIndex + 1] + ' ' + year;
}



export function FormatDateInStr(date){
//16/05/2021

  const dt = date.split('/')

  const ret = dt[0]+" "+monthNames[+dt[1]-1]+" "+dt[2];
  return ret;
}


export function FormatDate(_date) {

  let d = new Date();
  if(_date){
    d= new Date(_date)
  }
  //d.setDate(d.getDate() + 1)

  var day = d.getDate();
  var monthIndex = d.getMonth();
  var year = d.getFullYear();
  var dayIndex = d.getDay();

  var monNumber = String(monthIndex + 1);
  monNumber = monNumber.length === 1 ? '0' + monNumber : monNumber;

  return daysSort[dayIndex] + ", " + day + '.' + monNumber + '.' + year;
}



export function isScrolledIntoView(div, _offset = window.innerHeight / 2) {
  const bounding = div.getBoundingClientRect();
  const offset = _offset;
  return (
    bounding.top >= -offset &&
    bounding.left >= 0 &&
    bounding.bottom <= ((window.innerHeight + offset) || (window.document.documentElement.clientHeight + offset)) &&
    bounding.right <= (window.innerWidth || window.document.documentElement.clientWidth)
  );
}



/*
  Get current masthead abbrev

  Returns a string abbreviation indicating the current masthead.
*/

export function getCurrentMastheadAbbrev() {
  const url = getCurrentPageUrl();
  const urlParts = url.split('.');

  switch (urlParts[1]) {
    case 'smh':
      return 'smh';
    case 'theage':
      return 'theage';
    case 'watoday':
      return 'watoday';
    case 'canberratimes':
      return 'canberratimes';
    case 'brisbanetimes':
      return 'brisbanetimes';
    default:
      return 'smh';
  }
}


/*
  Get current page URL

  Returns the URL of the page in which the interactive is embedded or, failing that, the URL for
  the interactive itself.
*/

export function getCurrentPageUrl() {
  const isInIframe = window.location !== window.parent.location;
  if (isInIframe) {
    return document.referrer;
  } else {
    return document.location.href;
  }
}


/*
  Get parent's URL argument values

  Returns an object containing each of the provided URL parameters as keys and each of the 
  associated values as values.
*/

export function getParentsUrlArgumentValues() {
  var splitUrl = getCurrentPageUrl().split('?');
  var argumentSet;
  var urlArgumentsAndValues = {};

  if (splitUrl.length > 1) {
    var urlArguments = splitUrl[1].split('&');

    if (urlArguments.length > 0) {
      for (var i = 0; i < urlArguments.length; i++) {
        argumentSet = urlArguments[i].split('=');

        if (argumentSet.length >= 2) {
          urlArgumentsAndValues[argumentSet[0]] = decodeURIComponent(argumentSet[1]);
        }
      }
    }
  }

  return urlArgumentsAndValues;
}


/*
  Get screen size
  Returns the current screen size, according to standard Fairfax Blue/Nine Publishing breakpoints.
*/

export function getScreenSize() {
  const medium = 768;
  const large = 1024;
  const extraLarge = 1440;
  const w = document.body.clientWidth;
  return w < medium ? 'small' : w < large ? 'medium' : w < extraLarge ? 'large' : 'extra-large';
}




export const slugify = (str) => {
  return str
      .toString()
      .trim()
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w\-]+/g, "")
      .replace(/\-\-+/g, "-")
      .replace(/^-+/, "")
      .replace(/-+$/, "");
}



export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validateMessage(msg) {
  if(msg){
    if(msg.split(" ").length > 2){
       return true;
      }else{
      return false;
    }
  }
}



export const DivInfos = [
  {
  name: 'siteName',
  description: "It is important to confirm this when initially creating a new site. This name also defines the site root folder. Eg for 'Some site name' the root folder will be 'some-site-name'"
},
{
  name: 'assetId',
  description: "<span>Create a new article in INK and use its asset id here."
}
]


export default {
  getCurrentMastheadAbbrev,
  getCurrentPageUrl,
  getParentsUrlArgumentValues,
  getScreenSize,
  updatedDate,
  validateEmail,
  validateMessage,
  slugify
}
