import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
//import AuthenticatedRoute from "../utils/AuthenticatedRoute/AuthenticatedRoute";
import Loadable from "react-loadable";
import DynamicImportLoader from "../components/atoms/DynamicImportLoader/DynamicImportLoader";
import NotFoundPage from "../containers/templates/NotFoundPage/NotFoundPage";
import ComingSoon from "../components/atoms/ComingSoon/ComingSoon";

// 
// Helpers
// ------------------------------------------------------------------
const HomePageContainer = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Hub" */ "../containers/templates/HomePageContainer/HomePageContainer"),
  loading: DynamicImportLoader,
  modules: ["HomePageContainer"]
});


const PostContainer = Loadable({
  loader: () =>
    import("../containers/templates/PostContainer/PostContainer"),
  loading: () => null, // No loader here, because config tools each have their own
  modules: ["PostContainer"]
});


const PortfolioPageContainer = Loadable({
  loader: () =>
    import("../containers/templates/PortfolioPageContainer/PortfolioPageContainer"),
  loading: () => null, // No loader here, because config tools each have their own
  modules: ["PortfolioPageContainer"]
});


const PortfolioProjectContainer = Loadable({
  loader: () =>
    import("../containers/templates/PortfolioProjectContainer/PortfolioProjectContainer"),
  loading: () => null, // No loader here, because config tools each have their own
  modules: ["PortfolioProjectContainer"]
});


const SearchResultsContainer = Loadable({
  loader: () =>
    import("../containers/templates/SearchResultsContainer/SearchResultsContainer"),
  loading: () => null, // No loader here, because config tools each have their own
  modules: ["SearchResultsContainer"]
});





//
// Routes
// ------------------------------------------------------------------

const Routes = () => (
  <Switch>

    <Route path="/404" component={NotFoundPage} />
    {/* <Route path='/  ' exact insecure component={NotFoundPage} />
    <Route path='/portfolio/' exact insecure component={NotFoundPage} /> */}
    <Route exact path="/comingsoon" component={ComingSoon} />


    {/* Redirecting to richard lama portfolio for now */}
    <Route exact path="/" component={HomePageContainer} />

    <Route
      exact
      path="/:id"
      component={PostContainer}
    />


    <Route exact path='/cms' element={<NotFoundPage />} />
    <Route exact path='/cms/:id' element={<NotFoundPage />} />
  
    <Route  
      exact
      path="/search/:id"
      component={SearchResultsContainer}
    />

    <Route
      exact
      path="/portfolio/:id"     //http://localhost:3000/portfolio/richard-lama
      component={PortfolioPageContainer}
    />

    <Route
      exact
      path="/portfolio/:id/:id"
      component={PortfolioProjectContainer}
    /> 

    <Redirect to="/404" />

  </Switch>
);

export default Routes;





// import React from 'react';
// import {Route, Routes} from 'react-router-dom'
// import Loadable from "react-loadable";
// import VisjsTest from './components/organisms/VisjsTest/VisjsTest';
// import Login from './components/molecules/Login';
// import AuthenticatedRoute from './components/molecules/AuthenticatedRoute/AuthenticatedRoute';

// // 
// // Helpers
// // ------------------------------------------------------------------
// const HomePageContainer = Loadable({
//   loader: () =>
//     import(/* webpackChunkName: "Hub" */ "../containers/templates/HomePageContainer/HomePageContainer"),
//   loading: DynamicImportLoader,
//   modules: ["HomePageContainer"]
// });

// const AllRoutes = () => (
//   <Routes>
//     <Route exact path="/login"  element={<Login />} />
//     <Route exact path='/' element={<AuthenticatedRoute />}>
//       <Route exact path='/' element={<VisjsTest />} />
//     </Route>
//     <Route path="login?route=:route" element={<Login />} />
//   </Routes>
// );

// export default AllRoutes;




