// @flow

import React, { Component } from 'react';
import './SocialMediaBar.scss';
import {
  setupFacebookSharingLibrary,
  handleEmailShare,
  handleFacebookShare,
  handleTwitterShare,
  handleWhatsAppShare
} from '../../../utils/SocialSharing';//'@metro-prez/share-utils';
 
type Props = {
  shareUrl: string,
  title?: string,
  description?: string,
  screenSize: string,
  customContainerClassName?: string,
  customButtonClassName?: string,
  initFacebookLibrary?: boolean
};

type State = {};

class SocialMediaBar extends Component<Props, State> {
  static defaultProps = {
    description: '',
    title: '',
    customContainerClassName: '',
    customButtonClassName: '',
    initFacebookLibrary: false
  };

  componentDidMount() {
    const { initFacebookLibrary } = this.props;
    if (initFacebookLibrary) {
      setupFacebookSharingLibrary();
    }
  }

  render(){
    const {
      customContainerClassName,
      customButtonClassName,
      description,
      screenSize,
      shareUrl,
      title
    } = this.props;

    return (
      <div id="social-button-container" className={`social-button-container ${customContainerClassName}`}>
        <button
          className={`social-button-container__button facebook-share-button ${customButtonClassName}`}
          onClick={handleFacebookShare}
          data-share-title={title}
          data-share-description={description}
          data-share-link={shareUrl}
          type="button"
        >
          <div>
            <span>Share on Facebook</span>
            <svg height="1em" width="1em" aria-hidden="true" focusable="false">
              <use xlinkHref="#icon-facebook" />
            </svg>
          </div>
        </button>
        <button
          className={`social-button-container__button twitter-share-button ${customButtonClassName}`}
          onClick={handleTwitterShare}
          data-share-description={description}
          data-share-link={shareUrl}
          type="button"
        >
          <div>
            <span>Share on Twitter</span>
            <svg height="1em" width="1em" aria-hidden="true" focusable="false">
              <use xlinkHref="#icon-twitter" />
            </svg>
          </div>
        </button>
        {screenSize === 'small' ? (
          <button
            className={`social-button-container__button whatsapp-share-button ${customButtonClassName}`}
            type="button"
            data-share-title={title}
            data-share-description={description}
            data-share-link={shareUrl}
            onClick={handleWhatsAppShare}
          >
            <div>
              <span>Share on WhatsApp</span>
              <svg height="1em" width="1em" aria-hidden="true" focusable="false">
                <use xlinkHref="#icon-whatsapp" />
              </svg>
            </div>
          </button>
        ) : null}
        <button
          className={`social-button-container__button email-share-button ${customButtonClassName}`}
          data-share-title={title}
          data-share-description={description}
          data-share-link={shareUrl}
          onClick={handleEmailShare}
          type="button"
        >
          <div>
            <span>Share on Email</span>
            <svg height="1em" width="1em" aria-hidden="true" focusable="false">
              <use xlinkHref="#icon-email" />
            </svg>
          </div>
        </button>
      </div>
    );
  }
}

export default SocialMediaBar;