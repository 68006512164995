// @flow

import React from "react";
import Page from "../../../utils/Page/Page";
import NotFoundPage from "../../../components/organisms/NotFoundPage/NotFoundPage";

type Props = {
  user: string,
  authenticated: boolean
}; 

export const NOT_FOUND_ID = "not-found";
export const NOT_FOUND_TITLE = "Page not found 404";
export const NOT_FOUND_DESCRIPTION =
  "This is embarrassing. Seems we can't find the page you're looking for.";

const NotFoundPageContainer = (props: Props): React$Element<any> => (
  <Page
    id={NOT_FOUND_ID}
    title={NOT_FOUND_TITLE}
    description={NOT_FOUND_DESCRIPTION}
    noCrawl
  >
    <NotFoundPage user={props.user} authenticated={props.authenticated} />
  </Page>
);

export default NotFoundPageContainer;
